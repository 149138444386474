<template>
  <BaseDefault>
    <CHeader v-if="isOnMobile" container="fluid">
      <div class="d-flex tw-gap-1 tw-items-center tw-py-1">
        <CIcon
          as="button"
          :icon="cilSettings"
          size="xl"
          class="tw-cursor-pointer"
          @click="isNavSidebarVisible = !isNavSidebarVisible"
        />
      </div>
    </CHeader>
    <div class="tw-flex tw-min-h-[inherit]">
      <CSidebar
        ref="sidebarRef"
        :visible="isNavSidebarVisible"
        class="!tw-bg-white"
        size="lg"
      >
        <CSidebarNav>
          <CNavTitle>
            <span class="tw-text-xl">{{
              $t('settings.menu.option.settings')
            }}</span>
          </CNavTitle>
          <ul class="tw-overflow-y-auto">
            <li
              v-for="navigationItem in navigationItems"
              :key="navigationItem.id"
            >
              <CNavGroup
                v-if="navigationItem.childItems"
                :visible="isAnyChildRouteActive(navigationItem.childItems)"
              >
                <template #togglerContent>
                  <CIcon
                    custom-class-name="nav-icon"
                    :icon="navigationItem.icon"
                  />
                  <span class="tw-uppercase tw-font-semibold">{{
                    $t(navigationItem.translationKey)
                  }}</span>
                </template>
                <CNavItem
                  v-for="navigationItemChild in navigationItem.childItems"
                  :key="navigationItemChild.routeName"
                  href="#"
                  :active="isRouteActive(navigationItemChild.routeName)"
                  @click="navigateTo({ name: navigationItemChild.routeName })"
                >
                  <CIcon custom-class-name="nav-icon" icon="nav-icon-bullet" />
                  {{ $t(navigationItemChild.translationKey) }}
                </CNavItem>
              </CNavGroup>
            </li>
          </ul>
        </CSidebarNav>
      </CSidebar>
      <div class="container tw-my-11">
        <slot />
      </div>
    </div>
  </BaseDefault>
</template>

<script lang="ts" setup>
import { cilDollar, cilSettings, cilUser, cilChartLine } from '@coreui/icons'
import { useUniqueId } from '@coreui/vue-pro'
import BaseDefault from './base-default.vue'

interface INavigationItem {
  id: string
  routeName: string
  translationKey: string
  icon?: string[]
  childItems?: INavigationItem[]
}

const route = useRoute()
const sidebarRef = ref()
const isOnMobile = ref()
const isNavSidebarVisible = ref(true)

const isRouteActive = (routeName: string) => {
  return routeName === route.name
}

const isAnyChildRouteActive = (childItems: typeof navigationItems) => {
  return childItems.some((item) => isRouteActive(item.routeName))
}

const checkIsOnMobile = () => {
  return Boolean(
    getComputedStyle(sidebarRef.value.$el.nextSibling).getPropertyValue(
      '--cui-is-mobile',
    ),
  )
}

const handleResize = () => {
  isOnMobile.value = checkIsOnMobile()
}

const navigationItems = reactive<INavigationItem[]>([
  {
    id: useUniqueId().getUID(),
    routeName: '',
    translationKey: 'settings.menu.option.profile',
    icon: cilUser,
    childItems: [
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-user-basic-info',
        translationKey: 'settings.menu.option.basicInfo',
      },
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-user-api-key',
        translationKey: 'settings.menu.option.api',
      },
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-user-security',
        translationKey: 'settings.menu.option.security',
      },
    ],
  },
  {
    id: useUniqueId().getUID(),
    routeName: '',
    translationKey: 'settings.menu.option.plan-billing',
    icon: cilDollar,
    childItems: [
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-plan-and-billing-overview',
        translationKey: 'settings.menu.option.plan-overview',
      },
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-plan-and-billing-invoices',
        translationKey: 'settings.menu.option.plan-invoices',
      },
    ],
  },
  {
    id: useUniqueId().getUID(),
    routeName: '',
    translationKey: 'settings.menu.option.transactions',
    icon: cilChartLine,
    childItems: [
      {
        id: useUniqueId().getUID(),
        routeName: 'organizationId-settings-transactions-history',
        translationKey: 'settings.menu.option.history',
      },
    ],
  },
])

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
